import "./assets/styles/css/tailwind.css";
import "./assets/styles/sass/main.scss";
import "./assets/styles/sass/mixin.scss";
import Toaster from "@incuca/vue3-toaster";
import VueMask from "@devindex/vue-mask";
export default {
  install(app) {
    app.use(Toaster);
    app.use(VueMask);
  },
};
