export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mon-assurance"])},
  "Aide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide"])},
  "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier une assurance"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparez, trouvez votre assurance idéale et économisez"])},
  "money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de l'argent"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["du temps"])},
  "assuranceAuto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance automobile"])},
  "assuranceMoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance moto"])},
  "assuranceVoyage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance voyage"])},
  "assuranceHabitat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance habitation"])},
  "assurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Assurance"])},
  "Automobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automobile"])},
  "moto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moto à 2 ou 3 roues"])},
  "Habitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habitation"])},
  "Voyage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyage"])},
  "bestPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les meilleurs prix pour vous"])},
  "secondTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Économisez du temps et de l’argent sur vos contrats"])},
  "secondParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon-assurance.bj vous offre une expérience simple et sécurisée pour souscrire à vos assurances en 5 minutes, 100% en ligne."])},
  "secondParagraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandez un devis gratuitement dès maintenant !"])},
  "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparer des offres"])},
  "Mon-assurance.bj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon-assurance.bj "])},
  "moreChoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vous présente une multitude de choix."])},
  "autoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurer votre voiture n’a jamais été aussi simple !"])},
  "autoParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouvez l’assurance idéale au meilleur prix pour votre auto parmi les offres sur le marché."])},
  "motoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roulez désormais en toute sérénité !"])},
  "motoParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparez les offres d’assurance disponibles et faites des économies."])},
  "habitationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protégez votre habitat des dégâts et catastrophes !"])},
  "habitationParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souscrivez à l’assurance risque habitation qui sécurise votre propriété."])},
  "voyageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous partez en voyage d’affaires ou de tourisme!"])},
  "voyageParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optez en quelques clics pour l’assurance qui vous protège des imprévus lors de votre séjour."])},
  "thirdTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre mission: vous faciliter la vie !"])},
  "thirdParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon-assurance.bj est le comparateur d'assurances au Bénin qui vous recommande les meilleures offres personnalisées selon vos besoins. Choisissez votre contrat d’assurance en toute simplicité et sécurité."])},
  "sinistersDocs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos dossiers de sinistres"])},
  "sinistersParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclarez et suivez vos dossiers de sinistres et faites vous assister ou rembourser depuis le confort de votre salon."])},
  "spaceClientD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace client dédié"])},
  "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès 24h/24"])},
  "onlineDeclare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclaration en ligne"])},
  "docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi de dossier"])},
  "connecte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "feedBackTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ils nous recommandent"])},
  "feedBacKParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon-assurance.bj est noté 4,8/5 par ses assurés avec plus de 700 avis sur Google Avis"])},
  "contactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À votre écoute pour toute assistance"])},
  "footerT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" mon-assurance.bj est une marque de Azur Courtage, Société de prestations de services dont l’objet est le courtage/conseil en assurance et en réassurance dans les branches Incendie, Accidents, Risques Divers, Transports et Vie, régulièrement enregistré au Bénin et opérant sous l’agrément N°1733/M.E.F./DC/SGM/DGAE/D.A."])},
  "Extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
  "Réclamations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réclamations"])},
  "Carrières": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrières"])},
  "recrutons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous recrutons"])},
  "Mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions Légales"])},
  "Politique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
  "Cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies"])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
  "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
  "siege": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siège social Cotonou, quartier SCOA GBETO après l’Immeuble Amani en quittant ZONGO, au rez de chaussée de l’Immeuble carrelé bleu Lot 115A Ex ANPE en face du café de Bruxelles."])},
  "courriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les courriers devront être adressés au siège social."])},
  "Droits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tous droits réservés"])},
  "41Devs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["41Devs"])},
  "choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites votre choix en tenant compte de vos besoins et de des garanties proposées. Le prix ne doit pas être le seul critère de choix de votre police d’assurance. N’hésitez pas à nous contacter pour des conseils sur mesure."])},
  "clause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clause de non-responsabilité"])},
  "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous des interrogations sur mon-assurance.bj ?"])},
  "findAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouvez les Réponses à vos questions ici!"])},
  "findFreqAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions frequemment posées"])},
  "faqT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon-assurance.bj, à quoi ça sert au juste ?"])},
  "faqT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est-ce que je paie des frais supplémentaires en utilisant votre service ?"])},
  "faqT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment être sûr que votre comparateur est indépendant ?"])},
  "faqT4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes données personnelles sont-elles protégées ?"])},
  "ans1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le marché de l’assurance peut s’avérer extrêmement complexe. Il se compose d’une multitude d’acteurs : compagnies d’assurance, courtiers, mutuelles, acteurs internet ou encore d’agences indépendantes."])},
  "ans1p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A ceci s’ajoute la difficulté qu’il y a à comparer les contrats d’assurance dans tous leurs aspects : le prix, les franchises, les garanties et les services."])},
  "ans1p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mission du site Mon-assurance.bj est donc d’apporter plus de transparence et de simplicité dans l’assurance."])},
  "ans1p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour ce faire nous mettons à votre disposition un outil innovant qui en moins de 5 minutes vous permet de trouver l’assurance dont vous avez besoin : assurance automobile, assurance moto, assurance voyage, assurance habitation provenant d’un large panel d’assureurs accrédités. Nous vous proposons donc une multitude d’offres correspondant à tous types de profils selon le produit d’assurance recherché."])},
  "ans2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous proposons un service de comparaison entièrement gratuit et sans engagement. Les prix affichés sont tels que proposés par nos partenaires. Ils sont identiques aux prix que vous pourriez retrouver sur le site des assureurs. Nous ne prenons donc aucune commission sur leurs tarifs. Ce sont nos partenaires qui nous rémunèrent en qualité d’intermédiaire seulement lorsqu’il y a une souscription ou une mise en relation à votre initiative."])},
  "ans3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n’appartenons à aucune compagnie d’assurance opérant au Bénin, et aucune d’entre elles n’est dans notre capital. Nous sommes donc totalement objectifs et impartiaux dans les informations que nous vous présentons. Ainsi nous n’avons qu’un seul but : optimiser votre pouvoir d’achat en vous aidant à comparer de nombreux contrats d’assurance et à choisir celui qui correspond le mieux à vos attentes, en fonction du prix et des niveaux de garanties que vous recherchez. C’est simple et transparent."])},
  "ans3p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon-assurance.bj n’introduit aucun biais dans la présentation des offres. L’utilisateur définit lui-même le classement des résultats, par défaut les prix sont affichés par ordre croissant."])},
  "ans4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous faisons tout pour établir avec vous une relation de confiance et notre unique objectif est de vous aider dans la recherche de contrats d’assurance avantageux qui correspondent à vos besoins. Ainsi nous transmettons les données personnelles renseignées dans nos formulaires uniquement aux assureurs que vous aurez choisis. Nous nous engageons donc à ce qu’en aucun cas, vos données privées et votre adresse email ne soient transmises à des tiers ou utilisées à des fins commerciales sans votre autorisation. Vos informations et devis sont répertoriés sur votre espace personnel auquel vous seul avez accès. Par ailleurs, afin d’assurer la confidentialité de vos données vous disposez d’un accès sécurisé dès la page de saisie de vos codes d’accès jusqu’à votre espace personnel."])},
  "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyez votre message et nous vous répondons dans de brefs délais"])},
  "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom complet"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])},
  "yourMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre message"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
  "socity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société"])},
  "socityParagraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Site Mon-assurance.bj est une marque de Azur Courtage, Société de prestations de services dont l’objet est le courtage/conseil en assurance et en réassurance dans les branches Incendie, Accidents, Risques Divers, Transports et Vie, régulièrement enregistré au Bénin et opérant sous l’agrément N° 1733/M.E.F./DC/SGM/DGAE/D.A."])},
  "socityParagraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siège social : Cotonou, quartier SCOA GBETO après l’Immeuble Amani en quittant ZONGO, au rez de chaussée de l’Immeuble carrelé bleu Lot 115A Ex ANPE en face du café de Bruxelles."])},
  "socityParagraph3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le directeur de la publication est ……………."])},
  "socityParagraph4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plateforme est éditée par la Société Groupe 41DEVS SAS"])},
  "socityParagraph5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plateforme est hébergée par ………………………………."])},
  "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
  "serviceParagraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon-assurance.bj est un comparateur d’assurance pour les particuliers et les professionnels. L’utilisation du Site est soumise aux Conditions Générales du Site. Mon-assurance.bj décline toute responsabilité pour toute imprécision, inexactitude ou omission portant sur des informations disponibles sur le Site."])},
  "serviceParagraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon-assurance.bj  ne peut être tenu pour responsable de la modification des dispositions administratives et juridiques survenant après leur publication sur le Site. Les informations présentes sur le Site peuvent être corrigées, modifiées ou mises à jour à tout moment sans préavis."])},
  "courtage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courtage"])},
  "courtageParagraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon-assurance.bj  est régi par le Code des Assurances, a souscrit une assurance responsabilité civile professionnelle et une garantie financière en application des articles ………………………………………………."])},
  "courtageParagraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour toute réclamation, veuillez envoyer un email à "])},
  "courtageParagraph3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Mon-assurance.bj  est indépendant et n'a aucun lien capitalistique avec une compagnie d’assurance, mutuelles ou tout autre intervenant commercial sur le Site."])},
  "donneesPersonnelles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données personnelles"])},
  "donneesPersonnellesParagraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans votre processus de souscription à une assurance, Mon-assurance.bj  peut stocker des informations personnelles."])},
  "proprieteIntellectuelle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriété intellectuelle"])},
  "proprieteIntellectuelleParagraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La dénomination Mon-assurance.bj est une marque déposée et détenue par la société 41DEVS SAS."])},
  "proprieteIntellectuelleParagraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Site et tous les éléments qui le composent, tels que les images, logos, textes, charte graphique etc, sont la propriété exclusive de 41DEVS SAS et tous les droits de propriété intellectuelle qui y sont attachés, y compris le droit de reproduction, appartiennent à 41DEVS SAS."])},
  "proprieteIntellectuelleParagraph3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toute utilisation, reproduction, représentation, totale ou partielle, sous quelque forme et par quelque moyen que ce soit, du Site ou de l’un quelconque de ses éléments, à des fins et dans des conditions autres que celles prévues à l'article …….. du Code de la Propriété Intellectuelle est interdite, sauf autorisation expresse et préalable de 41DEVS SAS."])},
  "definitionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définition et nature des données à caractère personnel"])},
  "definitionParagraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de votre utilisation de notre site, nous pouvons être amenés à vous demander de nous communiquer des données à caractère personnel vous concernant ou les données de votre entreprise ou association."])},
  "definitionParagraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le terme « données à caractère personnel » désigne toutes les données qui permettent d’identifier un individu, ce qui correspond notamment à vos nom, prénoms, pseudonyme, photographie, adresses postales et de courrier électronique, numéros de téléphone, date de naissance, ainsi qu’à tout autre renseignement que vous choisirez de nous communiquer à votre sujet."])},
  "definitionParagraph3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de la souscription à une assurance, les données suivantes sont collectées :"])},
  "definitionParagraph3Liste1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom et prénom de chaque souscripteur"])},
  "definitionParagraph3Liste2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email et numéro de téléphone"])},
  "definitionParagraph3Liste3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance des souscripteurs"])},
  "definitionParagraph3Liste4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse postale"])},
  "definitionParagraph3Liste5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document d'identité"])},
  "charteObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objet de la charte de confidentialité"])},
  "charteObjectParagraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La présente charte a pour objet de vous informer sur les moyens que nous mettons en œuvre pour collecter vos données à caractère personnel, dans le respect le plus strict de vos droits."])},
  "charteObjectParagraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous indiquons à ce sujet que nous nous conformons, dans la collecte et la gestion de vos données à caractère personnel, à la loi n° 2009-09 portant protection des données à caractère personnel en République du Bénin."])},
  "responsableIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identité du responsable de la collecte de données"])},
  "responsableIdentityParagraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le responsable de la collecte de vos données à caractère personnel est la société, Groupe 41Devs SAS au  Capital de 5 000 000 FCFA, N° RCCM : RB/COT/22 B 32473 - N° IFU : 3202279087232 dont le siège social est : Cotonou, Gbedjromédé, Immeuble SYECA 2ème Etage  02BP1560. "])},
  "webSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site web:"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone:"])},
  "fondementTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur quels fondements vos données sont-elles collectées et utilisées ?"])},
  "fondementParagraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a/ le contrat : cette collecte est nécessaire afin d’exécuter le contrat conclu lorsque vous utilisez nos Services sur notre Application."])},
  "fondementParagraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b/ l’intérêt légitime : lorsque vous nous fournissez volontairement des données personnelles, nous les collectons afin de mieux répondre à vos demandes d’information sur notre Application."])},
  "fondementParagraph3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c/ l’obligation légale : lorsque le traitement de vos données est nécessaire au respect d’une obligation légale à laquelle nous sommes soumis."])},
  "fondementParagraph4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d/ le consentement : s’agissant de la collecte et du stockage de vos données personnelles par le biais des cookies (Google Analytics) utilisés sur l’Application."])},
  "dataCollecte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte des données à caractère personnel"])},
  "dataCollecteParagraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos données à caractère personnel sont collectées pour répondre à une ou plusieurs des finalités suivantes :"])},
  "dataCollecteParagraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Nous vous informons, lors de la collecte de vos données personnelles, si certaines données doivent être obligatoirement renseignées ou si elles sont facultatives."])},
  "dataCollecteParagraph3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Nous vous indiquons également quelles sont les conséquences éventuelles d’un défaut de réponse."])},
  "destinationDataCollecte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataires des données collectées"])},
  "destinationDataCollecteParagraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auront accès à vos données à caractère personnel:"])},
  "destinationDataCollecteParagraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- le personnel de notre société,"])},
  "destinationDataCollecteParagraph3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- notre expert comptable et son personnel,"])},
  "destinationDataCollecteParagraph4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- nos conseils juridiques (avocats),"])},
  "destinationDataCollecteParagraph5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- les assureurs et courtiers partenaires,"])},
  "destinationDataCollecteParagraph6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- les partenaires avec lesquels nous sommes susceptibles de travailler pour exécuter nos missions, avec votre accord."])},
  "destinationDataCollecteParagraph7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachez que vos données à caractère personnel ne feront jamais l’objet de cessions, locations ou échanges au bénéfice de tiers, sauf avec votre accord."])},
  "convertionTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de conservation des données à caractère personnel"])},
  "convertionTimeParagraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données sont conservées pendant toute la durée de votre inscription sur l’Application, et pour une durée de :"])},
  "convertionTimeParagraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Trois (3) ans à compter de la fin de la relation contractuelle."])},
  "convertionTimeParagraph3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Trois (3) ans minimum à compter de l’exercice du droit d’opposition concernant les informations permettant de prendre en compte votre droit."])},
  "convertionTimeParagraph4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Cinq (5) ans à compter de votre désinscription pour ce qui concerne le respect des obligations légales et réglementaires, ainsi que pour permettre d’établir la preuve d’un droit ou d’un contrat."])},
  "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurité"])},
  "securityParagraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous informons prendre toutes précautions utiles, mesures organisationnelles et techniques appropriées pour préserver la sécurité, l’intégrité et la confidentialité de vos données à caractère personnel et notamment, empêcher qu’elles soient déformées, endommagées ou que des tiers non autorisés y aient accès. Nous recourrons ou pouvons recourir également à des systèmes de paiement sécurisé conformes à l’état de l’art et à la réglementation applicable."])},
  "securityParagraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cookies sont des fichiers textes, souvent cryptés, stockés dans votre navigateur. Ils sont créés lorsque le navigateur d’un utilisateur charge un site internet donné : le site envoie des informations au navigateur, qui crée alors un fichier texte. Chaque fois que l’utilisateur revient sur le même site, le navigateur récupère ce fichier et l’envoie au serveur du site internet. On peut distinguer deux types de cookies, qui n’ont pas les mêmes finalités : les cookies techniques et les cookies publicitaires :"])},
  "securityParagraph3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cookies techniques sont utilisés tout au long de votre navigation, afin de la faciliter et d’exécuter certaines fonctions. Un cookie technique peut par exemple être utilisé pour mémoriser les réponses renseignées dans un formulaire ou encore les préférences de l’utilisateur s’agissant de la langue ou de la présentation d’un site internet, lorsque de telles options sont disponibles."])},
  "securityParagraph4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cookies publicitaires peuvent être créés non seulement par le site internet sur lequel l’utilisateur navigue, mais également par d’autres sites internet diffusant des publicités, annonces, widgets ou autres éléments sur la page affichée. Ces cookies peuvent notamment être utilisés pour effectuer de la publicité ciblée, c’est-à-dire de la publicité déterminée en fonction de la navigation de l’utilisateur."])},
  "securityParagraph5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous utilisons des cookies techniques. Ceux-ci sont stockés dans notre navigateur pour une période qui ne peut excéder six mois."])},
  "securityParagraph6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous utilisons ou pouvons utiliser Google Analytics qui est un outil statistique d’analyse d’audience qui génère un cookie permettant de mesurer le nombre de visites sur le site, le nombre de pages vues et l’activité des visiteurs. Votre adresse IP est également collectée pour déterminer la ville depuis laquelle vous vous connectez."])},
  "securityParagraph7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous rappelons à toutes fins utiles qu’il vous est possible de vous opposer au dépôt de cookies en configurant votre navigateur. Un tel refus pourrait toutefois empêcher le bon fonctionnement du site."])},
  "hebergement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement"])},
  "hebergementParagraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos droits sont les suivants :"])},
  "hebergementParagraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit à l’information : c’est justement la raison pour laquelle nous avons rédigé la présente charte"])},
  "hebergementParagraph3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit d’accès : vous avez le droit d’accéder à tout moment à l’ensemble de vos données à caractère personnel"])},
  "hebergementParagraph4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit de rectification : vous avez le droit de rectifier à tout moment vos données à caractère personnel inexactes, incomplètes ou obsolètes"])},
  "hebergementParagraph5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit à la portabilité : vous avez le droit de recevoir vos données à caractère personnel dans un format lisible et d’exiger leur transfert au destinataire de votre choix"])},
  "hebergementParagraph6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit à l’oubli : vous avez le droit d’exiger que vos données à caractère personnel soient effacées, et d’en interdire toute collecte future"])},
  "hebergementParagraph7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit d’introduire une réclamation : auprès d’une autorité de contrôle compétente (au Bénin, la Arcep), si vous considérez que le traitement de vos données à caractère personnel constitue une violation des textes applicables"])},
  "hebergementParagraph8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit d’opposition : vous avez le droit de vous opposer au traitement de vos données à caractère personnel. Notez toutefois que nous pourrons maintenir leur traitement malgré cette opposition, pour des motifs légitimes ou la défense de droits en justice. S’agissant de la prospection, vous pouvez vous y opposer à tout moment via le lien de désinscription situé au bas de chacun de nos emails de prospection"])},
  "hebergementParagraph9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit de définir des directives après votre mort : relatives à la conservation, à l'effacement et à la communication de vos données à caractère personnel, et désignant une personne chargée de leur exécution le cas échéant. Vous pourrez les transmettre à un tiers de confiance numérique certifié par l’Arcep lorsqu’il s’agit de directives générales, ou au aux coordonnées ci-dessous pour vos directives spécifiques. Vous pouvez modifier ou révoquer vos directives à tout moment."])},
  "modifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications"])},
  "modificationsParagraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous nous réservons le droit, à notre seule discrétion, de modifier à tout moment la présente charte, en totalité ou en partie. Ces modifications entreront en vigueur à compter de la publication de la nouvelle charte. Votre utilisation du site suite à l’entrée en vigueur de ces modifications vaudra reconnaissance et acceptation de la nouvelle charte. A défaut et si cette nouvelle charte ne vous convient pas, vous ne devrez plus accéder au site."])},
  "vigueur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrée en vigueur"])},
  "vigueurParagraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La présente charte est entrée en vigueur le ………………………."])},
  "cookiesParagraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un « cookie » est un petit fichier d’information envoyé sur le navigateur de l’Utilisateur et enregistré au sein du terminal de l’Utilisateur (ex : ordinateur, smartphone), (ci-après « Cookies »). Ce fichier comprend des informations telles que le nom de domaine de l’Utilisateur, le fournisseur d’accès Internet de l’Utilisateur, le système d’exploitation de l’Utilisateur, ainsi que la date et l’heure d’accès. Les Cookies ne risquent en aucun cas d’endommager le terminal de l’Utilisateur."])},
  "cookiesParagraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon-assurance.bj est susceptible de traiter les informations de l’Utilisateur concernant sa visite du Site, telles que les pages consultées, les recherches effectuées. Ces informations permettent à Mon-assurance.bj d’améliorer le contenu du Site, de la navigation de l’Utilisateur."])},
  "cookiesParagraph3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les Cookies facilitant la navigation et/ou la fourniture des services proposés par le Site, l’Utilisateur peut configurer son navigateur pour qu’il lui permette de décider s’il souhaite ou non les accepter de manière à ce que des Cookies soient enregistrés dans le terminal ou, au contraire, qu’ils soient rejetés, soit systématiquement, soit selon leur émetteur. L’Utilisateur peut également configurer son logiciel de navigation de manière à ce que l’acceptation ou le refus des Cookies lui soient proposés ponctuellement, avant qu’un Cookie soit susceptible d’être enregistré dans son terminal. Mon-assurance.bj informe l’Utilisateur que, dans ce cas, il se peut que les fonctionnalités de son logiciel de navigation ne soient pas toutes disponibles."])},
  "cookiesParagraph4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si l’Utilisateur refuse l’enregistrement de Cookies dans son terminal ou son navigateur, ou si l’Utilisateur supprime ceux qui y sont enregistrés, l’Utilisateur est informé que sa navigation et son expérience sur le Site peuvent être limitées. Cela pourrait également être le cas lorsque Mon-assurance.bj ou l’un de ses prestataires ne peut pas reconnaître, à des fins de compatibilité technique, le type de navigateur utilisé par le terminal, les paramètres de langue et d’affichage ou le pays depuis lequel le terminal semble connecté à Internet."])},
  "cookiesParagraph5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cas échéant, Mon-assurance.bj décline toute responsabilité pour les conséquences liées au fonctionnement dégradé du Site et des services éventuellement proposés par Mon-assurance.bj, résultant (i) du refus de Cookies par l’Utilisateur (ii) de l’impossibilité pour Mon-assurance.bj d’enregistrer ou de consulter les Cookies nécessaires à leur fonctionnement du fait du choix de l’Utilisateur. Pour la gestion des Cookies et des choix de l’Utilisateur, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d’aide du navigateur, qui permettra de savoir de quelle manière l’Utilisateur peut modifier ses souhaits en matière de Cookies."])},
  "cookiesParagraph6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À tout moment, l’Utilisateur peut faire le choix d’exprimer et de modifier ses souhaits en matière de Cookies. Mon-assurance.bj pourra en outre faire appel aux services de prestataires externes pour l’aider à recueillir et traiter les informations décrites dans cette section."])},
  "cookiesParagraph7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfin, en cliquant sur les icônes dédiées aux réseaux sociaux figurant sur le Site de Mon-assurance.bj, ses sous-domaines ou dans son application mobile et si l’Utilisateur a accepté le dépôt de cookies en poursuivant sa navigation sur le Site Internet ou l’application mobile, ces mêmes réseaux peuvent également déposer des cookies sur vos terminaux (ordinateur, tablette, téléphone portable)."])},
  "cookiesParagraph8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces types de cookies ne sont déposés sur vos terminaux qu’à condition que vous y consentiez, en continuant votre navigation sur le Site Internet de Mon-assurance.bj. À tout moment, l’Utilisateur peut néanmoins revenir sur son consentement à ce que Mon-assurance.bj dépose ce type de cookies."])},
  "recommandation1Poste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptable"])},
  "recommandation1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J’ai apprécié mon expérience avec Mon-assurance.bj. C’est facile et rapide d’obtenir un bon prix pour l’assurance qui nous convient le mieux. Je suis satisfaite."])},
  "recommandation2Poste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrepreneur"])},
  "recommandation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assuré en quelques minutes avec toutes les garanties que je souhaitais. Une plateforme de gestion de tous mes contrats. Simple et efficace. Je recommande vivement !"])},
  "recommandation3Poste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homme d’affaires"])},
  "recommandation3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis ravi de mon expérience ! C'est la première fois que je me sers d'un service comme celui-ci et je n'ai pas été déçu. Le processus de comparaison des assurances a été simple et rapide, et j'ai réussi à trouver une couverture qui correspond à mes besoins à un prix très raisonnable."])},
  "recommandation4Poste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecin"])},
  "recommandation4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super service ! Aucune idée que je pouvais économiser autant d’argent sur mon assurance auto !"])},
  "marque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque"])},
  "modele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle"])},
  "circDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date mise circulation"])},
  "motPuissance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puissance moteur"])},
  "chassis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chassis"])},
  "placeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nbr. Place"])},
  "couverture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couverture"])},
  "takeEffect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prise d’effet"])},
  "expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration"])},
  "assureur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assureur"])},
  "manageContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer mon contrat"])},
  "intitule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "travelDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de voyage"])},
  "batimentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de batiment"])},
  "batimentAdress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse du batiment"])},
  "monthlyRent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyer mensuel"])},
  "batimentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur du batiment"])},
  "contentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur du contenu"])},
  "categorie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
  "carburation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carburation"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])}
}