import { createRouter, createWebHistory } from "vue-router";
import pathLoader from "../plugins/loader";
const routes = [
  {
    path: "/",
    name: "Home",
    component: pathLoader("home"),
  },
  {
    path: "/verification",
    name: "verification",
    component: pathLoader("verification"),
  },
  {
    path: "/faq",
    name: "faq",
    component: pathLoader("faq"),
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: pathLoader("contact-us"),
  },
  {
    path: "/mentions-legales",
    name: "mentions-legales",
    component: pathLoader("mentions-legales"),
  },
  {
    path: "/politique-de-confidentialite",
    name: "politique-de-confidentialite",
    component: pathLoader("politique-de-confidentialite"),
  },
  {
    path: "/cookies",
    name: "cookies",
    component: pathLoader("cookies"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
