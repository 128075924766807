import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import install from "./install";
import i18n from "./i18n";
import { VueSvgIconPlugin } from "@yzfe/vue3-svgicon";
import "@yzfe/svgicon/lib/svgicon.css";
// import VueDatePicker from "@vuepic/vue-datepicker";
// import "@vuepic/vue-datepicker/dist/main.css";
import vClickOutside from "click-outside-vue3";
import easySpinner from "vue-easy-spinner";
import Toaster from "@incuca/vue3-toaster";
import AOS from "aos";
import "aos/dist/aos.css";

createApp(App)
  .use(VueSvgIconPlugin, { tagName: "icon" })
  .use(i18n)
  .use(store)
  .use(router)
  .use(install)
  .use(vClickOutside)
  .use(Toaster)
  .use(AOS)
  .use(easySpinner, {
    /*options*/
    prefix: "easy",
  })
  // .component("VueDatePicker", VueDatePicker)
  .mount("#app");
